// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login'
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login'
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    }
];

// 页面路由配置
export default [
    {
        path: '/admin-manage/admin-manage-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/admin-manage/admin-manage-edit/index.jsx'),
    },
    {
        path: '/hook/admin-manage-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/admin-manage/admin-manage-edit/UserListHooks.jsx'),
    },
    {
        path: '/article-list/article-list-add',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/article-list/article-list-add/index.jsx'),
    },
    {
        path: '/hook/information-business-add',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/article-list/article-list-add/UserListHooks.jsx'),
    },
    {
        path: '/base-data/base-data-country',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/base-data/base-data-country/index.jsx'),
    },
    {
        path: '/base-data/base-data-label',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/base-data/base-data-label/index.jsx'),
    },
    {
        path: '/hook/base-data-label',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/base-data/base-data-label/UserListHooks.jsx'),
    },
    {
        path: '/charts',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/chart/index.jsx'),
    },
    {
        path: '/consult-risk/consult-risk-add',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/consult-risk/consult-risk-add/index.jsx'),
    },
    {
        path: '/hook/consult-risk-add',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/consult-risk/consult-risk-add/UserListHooks.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/example/table-editable',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/examples/table-editable/index.jsx'),
    },
    {
        path: '/gen',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/gen/index.jsx'),
    },
    {
        path: '/',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/home/index.jsx'),
    },
    {
        path: '/hooks-test',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/hooks-test/index.jsx'),
    },
    {
        path: '/react-use',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/hooks-test/ReactUse.jsx'),
    },
    {
        path: '/hooks-callback',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/hooks-test/UseCallback.jsx'),
    },
    {
        path: '/hooks-effect',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/hooks-test/UseEffect.jsx'),
    },
    {
        path: '/use-state',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/hooks-test/UseState.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/iframe/index.jsx'),
    },
    {
        path: '/information-memberActivity/information-memberActivity-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-memberActivity/information-memberActivity-edit/index.jsx'),
    },
    {
        path: '/hook/information-memberActivity-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-memberActivity/information-memberActivity-edit/UserListHooks.jsx'),
    },
    {
        path: '/information-policy/information-policy-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-policy/information-policy-edit/index.jsx'),
    },
    {
        path: '/hook/information-policy-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-policy/information-policy-edit/UserListHooks.jsx'),
    },
    {
        path: '/information-thinkTank/information-thinkTank-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-thinkTank/information-thinkTank-edit/index.jsx'),
    },
    {
        path: '/hook/information-thinkTank-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/information-thinkTank/information-thinkTank-edit/UserListHooks.jsx'),
    },
    {
        path: '/links/links-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/links/links-edit/index.jsx'),
    },
    {
        path: '/hook/links-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/links/links-edit/UserListHooks.jsx'),
    },
    {
        path: '/login',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/login/index.jsx'),
    },
    {
        path: '/member-info/member-info-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/member-info/member-info-edit/index.jsx'),
    },
    {
        path: '/hook/member-info-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/member-info/member-info-edit/UserListHooks.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/page-data/pageData-scroll-activity',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-scroll-activity/index.jsx'),
    },
    {
        path: '/page-data/pageData-scroll-arcticle',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-scroll-arcticle/index.jsx'),
    },
    {
        path: '/page-data/pageData-scroll-message',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-scroll-message/index.jsx'),
    },
    {
        path: '/page-data/pageData-scroll-question',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-scroll-question/index.jsx'),
    },
    {
        path: '/page-data/pageData-scroll-report',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-scroll-report/index.jsx'),
    },
    {
        path: '/page-data/pageData-stick-arcticle',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/page-data/pageData-stick-arcticle/index.jsx'),
    },
    {
        path: '/preview/:id',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/preview/index.jsx'),
    },
    {
        path: '/roles',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/role/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/setting/index.jsx'),
    },
    {
        path: '/team/team-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/team/team-edit/index.jsx'),
    },
    {
        path: '/hook/team-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/team/team-edit/UserListHooks.jsx'),
    },
    {
        path: '/technology-material/technology-material-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/technology-material/technology-material-edit/index.jsx'),
    },
    {
        path: '/hook/technology-material-edit',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/technology-material/technology-material-edit/UserListHooks.jsx'),
    },
    {
        path: '/users',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/users/index.jsx'),
    },
    {
        path: '/hook/users',
        component: () => import('/Users/xiaomin/internation-react-admin-master/src/pages/users/UserListHooks.jsx'),
    }
];
    