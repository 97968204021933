// import {ajax} from 'src/commons/ajax';
/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
export default function getMenus(userId) {
    // return ajax.get('/menus', {userId}).then(res => {
    //     return (res || []).map(item => ({key: item.id, parentKey: item.parentId, ...item}));
    // });

    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    return Promise.resolve([
        { key: 'article', text: '文章管理', icon: 'book', order: 900 },
        {
            key: 'article-list', parentKey: 'article', text: '文章列表',
            icon: 'book', path: '/article-list/article-list-add', order: 600
        },

        { key: 'work', text: '研究人员管理', icon: 'book', order: 900 },
        {
            key: 'work-list', parentKey: 'work', text: ' 研究人员列表',
            icon: 'book', path: '/team/team-edit', order: 600
        },

        { key: 'base-data', text: '基础数据管理', icon: 'book', order: 900 },
        {
            key: 'base-data-country', parentKey: 'base-data', text: '国家列表',
            icon: 'book', path: '/base-data/base-data-country', order: 600
        },
        {
            key: 'base-data-label', parentKey: 'base-data', text: '关键词列表',
            icon: 'book', path: '/base-data/base-data-label', order: 600
        },

        { key: 'page-data', text: '页面数据设置', icon: 'book', order: 900 },
        {
            key: 'pageData-stick-arcticle', parentKey: 'page-data', text: '首页置顶文章',
            icon: 'book', path: '/page-data/pageData-stick-arcticle', order: 600
        },
        {
            key: 'pageData-scroll-arcticle', parentKey: 'page-data', text: '首页滚动文章',
            icon: 'book', path: '/page-data/pageData-scroll-arcticle', order: 600
        },
        {
            key: 'pageData-scroll-report', parentKey: 'page-data', text: '首页滚动报告',
            icon: 'book', path: '/page-data/pageData-scroll-report', order: 600
        },
        {
            key: 'pageData-scroll-activity', parentKey: 'page-data', text: '首页滚动活动',
            icon: 'book', path: '/page-data/pageData-scroll-activity', order: 600
        },
        {
            key: 'pageData-scroll-message', parentKey: 'page-data', text: '首页滚动信息图',
            icon: 'book', path: '/page-data/pageData-scroll-message', order: 600
        },
        {
            key: 'pageData-scroll-question', parentKey: 'page-data', text: '首页滚动QA',
            icon: 'book', path: '/page-data/pageData-scroll-question', order: 600
        },
        // { key: 'consult-risk', text: '风险评估管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'consult-risk-add', parentKey: 'consult-risk', text: '查看风险评估', icon: 'book', path: '/consult-risk/consult-risk-add', order: 600 },

        // { key: 'agent-interflow', text: '国际交流管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'agent-interflow-edit', parentKey: 'agent-interflow', text: '编辑国际交流', icon: 'book', path: '/agent-interflow/agent-interflow-edit', order: 600 },

        // { key: 'team', text: '专家信息管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'team-edit', parentKey: 'team', text: '编辑专家信息', icon: 'book', path: '/team/team-edit', order: 600 },

        // { key: 'technology-material', text: '物项鉴定信息管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'technology-material-edit', parentKey: 'technology-material', text: '编辑物项鉴定信息', icon: 'book', path: '/technology-material/technology-material-edit', order: 600 },

        // { key: 'links', text: '友情链接管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'links-edit', parentKey: 'links', text: '编辑友情链接管理', icon: 'book', path: '/links/links-edit', order: 600 },

        // { key: 'member-info', text: '会员信息管理', icon: 'book', path: '/users', order: 900 },
        // { key: 'member-info-edit', parentKey: 'member-info', text: '编辑会员信息', icon: 'book', path: '/member-info/member-info-edit', order: 600 },

        // /admin-manage/admin-manage-edit

        { key: 'admin-manage', text: '管理员信息管理', icon: 'book', path: '/admin-manage/admin-manage-edit', order: 900 }







        // { key: 'antDesign', text: 'Ant Design 官网', icon: 'ant-design', url: 'https://ant-design.gitee.io', target: '', order: 2000 },
        // { key: 'document', text: '文档', icon: 'book', url: 'http://shubin.wang/docs', target: '_blank', order: 1200 },

        // { key: 'customer-header', text: '自定义头部', icon: 'api', path: '/example/customer-header', order: 998 },
        // { key: 'user', text: '用户管理', icon: 'user', path: '/users', order: 900 },
        // { key: 'role', text: '角色管理', icon: 'lock', path: '/roles', order: 900 },
        // { key: 'menu', text: '菜单管理', icon: 'align-left', path: '/menu-permission', order: 900 },
        // { key: 'gen', text: '代码生成', icon: 'code', path: '/gen', order: 900 },
        // { key: 'page404', text: '404页面不存在', icon: 'file-search', path: '/404', order: 700 },
        // { key: 'example', text: '菜单界面设置', icon: 'align-left', path: '/settings', order: 600 },
        // { key: 'table-editable', parentKey: 'example', text: '可编辑表格', icon: 'align-left', path: '/example/table-editable', order: 600 },
    ]);
}
